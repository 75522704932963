import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { ContentItem, ControlledTextField, MainTable } from 'core/components'
import SearchComponent from 'core/components/SearchComponent'
import { AddProductErrorCode, ValueRange } from 'core/consts'
import { useSnackbar } from 'core/contexts/SnackbarProvider'
import { ApiErrorRes, Item, ProductOrder } from 'core/types'
import { formatPrice } from 'core/utils/formatUtils'
import { Formik } from 'formik'
import { createFilters } from 'items/helpers/itemHelpers'
import {
  mapVendorProductsToSwap as mapVendorProductsToAdd,
  mapVendorProductsToSwapColumns as mapVendorProductsToAddColumns,
} from 'vendor/helpers/mapVendorProductsToSwap'
import { useAddProductOrder } from 'vendor/hooks'
import { useGetVendorCatalog } from 'vendor/hooks/useGetVendorCatalog'
import { AddProductToOrder } from 'vendor/types/types'
import * as Yup from 'yup'

type AddOrderDetailDialogProps = {
  onClose: () => void
  orderId: string
  vendorId: string
  refetchOrder: () => void
  orderProducts: ProductOrder[]
}

const AddProductDialog: React.FC<AddOrderDetailDialogProps> = ({ onClose, vendorId, orderId, refetchOrder, orderProducts }) => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedProduct, setSelectedProduct] = useState<Item | null>(null)
  const customStock = selectedProduct?.stock === ValueRange.MAX_POSITIVE_VALUE ? 1000 : selectedProduct?.stock
  const { addProductOrder, isLoading: isAddingProduct } = useAddProductOrder()
  const { vendorCatalog, isLoading: isLoadingVendorCatalog } = useGetVendorCatalog(
    {
      Page: page + 1,
      PageSize: rowsPerPage,
      Filters: createFilters(searchTerm, { favouriteOnly: false, previouslyOrdered: false, isInStock: true }),
    },
    vendorId,
    ['Vendor', 'List', vendorId, page, rowsPerPage, searchTerm],
  )

  const handleAddProductToOrder = (addProduct: AddProductToOrder) => {
    addProductOrder(addProduct, {
      onSuccess: () => {
        onClose()
        refetchOrder()
        snackbar.success(t('vendors.update.success'))
      },
      onError: (error: AxiosError<ApiErrorRes>) => {
        const errorCode = error.response?.data?.code

        switch (errorCode) {
          case AddProductErrorCode.DISALLOWED_ADD:
            snackbar.error(t('vendorOrders.disallowedAddProductToOrder'))
            break
          default:
            snackbar.error(t('vendors.update.error'))
            break
        }
      },
    })
  }

  const handlePageChange = (newPage: number) => {
    setPage(newPage)
  }

  const handlePageSizeChange = (newPageSize: number) => {
    setRowsPerPage(newPageSize)
  }

  const initialValues = {
    quantity: 1,
  }

  const validationSchema = Yup.object({
    quantity: Yup.number()
      .moreThan(0, t('common.validations.moreThanZero'))
      .lessThan(customStock ? customStock + 1 : 0, t('common.validations.lessThan', { quantity: customStock ? customStock + 1 : 0 }))
      .required(t('common.validations.required')),
  })

  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Typography>{t('vendorOrders.addProductToOrder')}</Typography>
      </DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={values => {
          handleAddProductToOrder({
            quantity: Number(values.quantity),
            productId: selectedProduct?.id ?? '',
            vendorId,
            orderId,
          })
        }}
      >
        {(formik: any) => {
          const handleSelect = (item: Item) => {
            setSelectedProduct(item)
            formik.setFieldValue('quantity', 1)
          }
          return (
            <form onSubmit={formik.handleSubmit}>
              <DialogContent>
                {selectedProduct ? (
                  <Box>
                    <ContentItem label={t('vendorOrders.product')} value={selectedProduct.name} inline />
                    <ContentItem label={t('vendorOrders.price')} value={formatPrice(selectedProduct.price)} inline />
                    <ControlledTextField
                      name="quantity"
                      type="number"
                      label={t('acceptOrder.quantity')}
                      helperText={`${t('orders.availableStock')} ${customStock}`}
                      sx={{ my: 2 }}
                    />
                  </Box>
                ) : (
                  <Typography mt={2} color="warning.main">
                    {t('vendorOrders.selectProduct')}
                  </Typography>
                )}

                <Divider sx={{ my: 2 }} />
                <SearchComponent onSearch={setSearchTerm} sx={{ mb: 2 }} />
                <Box height="20rem" overflow="auto" margin="dense">
                  {vendorCatalog && (
                    <MainTable
                      columns={mapVendorProductsToAddColumns(t)}
                      rows={mapVendorProductsToAdd(
                        vendorCatalog.items,
                        {
                          handleSelect: handleSelect,
                        },
                        orderProducts,
                        t,
                      )}
                      count={vendorCatalog?.totalCount}
                      pagination={{
                        page: page,
                        onPageChange: handlePageChange,
                        pageSize: rowsPerPage,
                        onPageSizeChange: handlePageSizeChange,
                      }}
                      loading={isLoadingVendorCatalog}
                    />
                  )}
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose} variant="outlined" startIcon={<CloseIcon />}>
                  {t('common.cancel')}
                </Button>
                <LoadingButton loading={isAddingProduct} variant="contained" type="submit">
                  {t('common.save')}
                </LoadingButton>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}

export default AddProductDialog
