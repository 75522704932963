import { Suspense, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Routes, useLocation } from 'react-router-dom'

import { useAuth } from 'auth/contexts/AuthProvider'
import { MainLayout } from 'core/components'
import Loader from 'core/components/Loader'
import dayjs from 'dayjs'

import { routes } from './config/routes'
import PrivateRoute from './core/components/PrivateRoute'

const AppRoutes = () => {
  const { pathname } = useLocation()
  const { t, i18n } = useTranslation()
  const { userInfo } = useAuth()
  const roles = userInfo?.roles ?? []

  useEffect(() => {
    dayjs.locale(i18n.language)
  }, [i18n.language])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [pathname])

  return (
    <Routes>
      {routes(t, roles).map(el => (
        <Route
          key={el.path}
          path={el.path}
          element={
            el.noAuth ? (
              <Suspense fallback={<Loader />}>{el.element}</Suspense>
            ) : (
              <PrivateRoute roles={el.roles}>
                {el.fullWidth ? (
                  <Suspense fallback={<Loader />}>{el.element}</Suspense>
                ) : (
                  <MainLayout title={el.title}>
                    <Suspense fallback={<Loader />}>{el.element}</Suspense>
                  </MainLayout>
                )}
              </PrivateRoute>
            )
          }
        >
          {el.subroutes &&
            el.subroutes.map(subroute => (
              <Route
                key={subroute.path}
                path={subroute.path}
                element={<PrivateRoute roles={subroute.roles}>{subroute.element}</PrivateRoute>}
              />
            ))}
        </Route>
      ))}
    </Routes>
  )
}

export default AppRoutes
