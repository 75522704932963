import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'
import { getUserRoles } from 'auth/contexts/AuthProvider'
import { MainPaper, MainTable } from 'core/components'
import { UserRole } from 'core/consts'
import { useGetAllOrders } from 'management/hooks'
import { mapAdminOrdersColumns, mapAdminOrdersData } from 'management/ordersManagement/mapAdminOrders'
import AchievementWidgetVendor from 'vendor/widgets/AchievementWidgetVendor'
import MeetingWidgetsVendor from 'vendor/widgets/MeetingWidgetsVendor'
import PersonalTargetsWidgetVendor from 'vendor/widgets/PersonalTargetWidgetVendro'
import ViewsWidgetVendor from 'vendor/widgets/ViewsWidgetVendor'

import AchievementWidget from '../widgets/AchievementWidget'
import MeetingWidgets from '../widgets/MeetingWidgets'
import PersonalTargetsWidget from '../widgets/PersonalTargetsWidget'
import ViewsWidget from '../widgets/ViewsWidget'
import WelcomeWidget from '../widgets/WelcomeWidget'

const Home = () => {
  const { t } = useTranslation()
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(5)
  const roles = getUserRoles()
  const isAdmin = roles?.includes(UserRole.ADMIN)
  const isCompanyOwner = roles?.includes(UserRole.COMPANY_ADMIN)
  const isTestVendor = roles?.includes(UserRole.TEST_VENDOR)

  const { allOrders, isFetching } = useGetAllOrders({
    Page: currentPage + 1,
    PageSize: pageSize,
  })

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage)
  }

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize)
  }

  return (
    <React.Fragment>
      {isCompanyOwner && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <WelcomeWidget />
            <PersonalTargetsWidget />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <AchievementWidget />
            <ViewsWidget />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MeetingWidgets />
          </Grid>
        </Grid>
      )}
      {isAdmin && (
        <>
          <WelcomeWidget />
          <MainPaper sx={{ position: 'relative' }}>
            <MainTable
              columns={mapAdminOrdersColumns(t)}
              rows={mapAdminOrdersData(allOrders ?? { totalCount: 0, items: [] }, t)}
              count={allOrders?.totalCount}
              pagination={{
                page: currentPage,
                onPageChange: handlePageChange,
                pageSize,
                onPageSizeChange: handlePageSizeChange,
              }}
              loading={isFetching}
            />
          </MainPaper>
        </>
      )}
      {isTestVendor && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <WelcomeWidget />
            <PersonalTargetsWidgetVendor />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <AchievementWidgetVendor />
            <ViewsWidgetVendor />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MeetingWidgetsVendor />
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  )
}

export default Home
