import { useEffect, useState } from 'react'
import { CookieConsent as CookiesBanner } from 'react-cookie-consent'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useAuth } from 'auth/contexts/AuthProvider'
import { RouteVal } from 'config/routeTypes'
import { notProtectedRoutes } from 'config/routes'
import { drawerWidth } from 'core/config/layout'
import { APP_SKALO_SERVER } from 'core/consts'
import { secondaryBgr } from 'core/theme/palette'

export const CookieConsent = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isScreenSmall = useMediaQuery(theme.breakpoints.down('lg'))
  const width = isScreenSmall ? 0 : drawerWidth
  const { isAuthenticated } = useAuth()
  const { pathname } = useLocation()

  const [onSpecialPage, setOnSpecialPage] = useState(false)

  useEffect(() => {
    const containsAny = notProtectedRoutes.some(el => el.path !== '/' && el.path !== '*' && pathname.includes(el.path))
    if (containsAny || pathname === '/' || pathname.includes(RouteVal.MANUAL) || !isAuthenticated) {
      setOnSpecialPage(true)
    } else {
      setOnSpecialPage(false)
    }
  }, [pathname, isAuthenticated])

  return (
    <CookiesBanner
      style={{
        background: secondaryBgr,
        zIndex: '1200',
        color: theme.palette.grey[900],
        marginLeft: onSpecialPage ? 0 : width,
        width: onSpecialPage ? '100%' : `calc(100% - ${width}px)`,
      }}
      buttonStyle={{
        backgroundColor: theme.palette.success.main,
        color: theme.palette.grey[50],
        padding: '0.375rem 1rem',
        borderRadius: '0.25rem',
      }}
      declineButtonStyle={{
        backgroundColor: theme.palette.error.main,
        color: theme.palette.grey[50],
        padding: '0.375rem 1rem',
        borderRadius: '0.25rem',
      }}
      buttonText={t('cookieConsent.acceptConditions')}
      declineButtonText={t('cookieConsent.rejectConditions')}
      enableDeclineButton
      expires={365}
      debug={APP_SKALO_SERVER === 'DEV'}
      cookieName="skalo-www-cookie"
    >
      {t('cookieConsent.content')}
    </CookiesBanner>
  )
}
