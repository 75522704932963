import { CurrencyExchange, Description, ListAlt, LiveHelp, MonetizationOn, OpenInNew, ReceiptLong, ThumbUpAlt } from '@mui/icons-material'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import HelpIcon from '@mui/icons-material/Help'
import HomeIcon from '@mui/icons-material/Home'
import InventoryIcon from '@mui/icons-material/Inventory'
import RestaurantIcon from '@mui/icons-material/Restaurant'
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket'
import { Box } from '@mui/material'
import { UserRole, UserRoute } from 'core/consts'
import { TFunction } from 'i18next'

import { ManualTab, RouteVal } from './routeTypes'

export interface MenuRoute {
  title: React.ReactNode
  to?: string
  expandKey?: string
  icon: any
  roles: UserRole[]
  subRoutes?: {
    title: React.ReactNode
    to: string
    blank?: boolean
    icon?: any
  }[]
  divider?: boolean
  blank?: boolean
}

export const menuRoutes = (t: TFunction): MenuRoute[] => [
  {
    title: t('admin.drawer.menu.home'),
    to: UserRoute.HOME,
    icon: <HomeIcon />,
    roles: [UserRole.CLIENT, UserRole.ADMIN, UserRole.VENDOR, UserRole.RESTAURANT_MANAGER],
  },
  {
    title: t('admin.drawer.menu.orders'),
    to: '/catalog',
    icon: <ShoppingBasketIcon />,
    roles: [UserRole.CLIENT, UserRole.RESTAURANT_MANAGER],
  },
  {
    title: t('page.title.catalog'),
    to: '/admin-catalog',
    icon: <ShoppingBasketIcon />,
    roles: [UserRole.ADMIN],
  },
  {
    title: t('admin.drawer.menu.vendorOrders'),
    to: '/vendor-orders',
    icon: <ListAlt />,
    roles: [UserRole.VENDOR],
  },
  {
    title: t('admin.drawer.menu.vendorCatalog'),
    to: '/vendor-catalog',
    icon: <InventoryIcon />,
    roles: [UserRole.VENDOR],
  },
  {
    title: t('admin.drawer.menu.myOrders'),
    to: '/my-orders',
    icon: <ListAlt />,
    roles: [UserRole.CLIENT, UserRole.RESTAURANT_MANAGER],
  },
  {
    title: t('admin.drawer.menu.shoppingLists'),
    to: '/shopping-list',
    icon: <ReceiptLong />,
    roles: [UserRole.CLIENT, UserRole.RESTAURANT_MANAGER],
  },
  {
    title: `${t('admin.drawer.menu.finances')}`,
    to: '/vendor-finances',
    icon: <MonetizationOn />,
    roles: [UserRole.TEST_VENDOR],
  },
  {
    title: t('page.title.clientFinances'),
    to: '/client-finances',
    icon: <MonetizationOn />,
    roles: [UserRole.CLIENT],
  },
  {
    title: t('page.title.clientInvoices'),
    to: '/client-invoices',
    icon: <Description />,
    roles: [UserRole.CLIENT],
  },
  {
    title: t('admin.drawer.menu.restaurants'),
    to: '/restaurants',
    icon: <RestaurantIcon />,
    roles: [UserRole.CLIENT],
  },
  {
    title: t('admin.drawer.menu.vendorConfiguration'),
    to: '/vendor-configuration',
    icon: <AdminPanelSettingsIcon />,
    roles: [UserRole.VENDOR],
  },
  {
    title: t('admin.drawer.menu.allOrders'),
    to: '/admin-orders',
    icon: <ListAlt />,
    roles: [UserRole.ADMIN],
  },
  {
    title: t('admin.drawer.menu.dotykacka'),
    to: '/dotykacka',
    icon: <AdminPanelSettingsIcon />,
    roles: [UserRole.ADMIN],
  },
  {
    title: t('admin.drawer.menu.administration'),
    icon: <AdminPanelSettingsIcon />,
    roles: [UserRole.ADMIN],
    expandKey: 'adminManagement',
    subRoutes: [
      {
        title: t('admin.drawer.menu.usersManagement'),
        to: `/${RouteVal.USERS_MANAGEMENT}`,
      },
      {
        title: t('admin.drawer.menu.vendorsManagement'),
        to: `/${RouteVal.VENDORS_MANAGEMENT}`,
      },
      {
        title: t('admin.drawer.menu.clientsManagement'),
        to: `/${RouteVal.CLIENTS_MANAGEMENT}`,
      },
      {
        title: t('admin.drawer.menu.deliverySitesManagement'),
        to: `/${RouteVal.DS_MANAGEMENT}`,
      },
      {
        title: t('admin.drawer.menu.paymentsManagement'),
        to: `/${RouteVal.PAYMENTS_MANAGEMENT}`,
      },
    ],
  },
  {
    title: t('page.title.platformSettings'),
    to: '/platform-settings',
    icon: <CurrencyExchange />,
    roles: [UserRole.ADMIN],
  },
  {
    title: t('admin.drawer.menu.userManual'),
    icon: <HelpIcon />,
    roles: [UserRole.CLIENT, UserRole.RESTAURANT_MANAGER, UserRole.ADMIN, UserRole.VENDOR, UserRole.RESTAURANT_MANAGER],
    expandKey: 'manual',
    divider: true,
    subRoutes: [
      {
        title: <TitleBox blank>{t('admin.drawer.menu.faq')}</TitleBox>,
        to: `/${RouteVal.MANUAL}/${ManualTab.FAQ}`,
        icon: <LiveHelp fontSize="small" />,
        blank: true,
      },
      {
        title: <TitleBox blank>{t('admin.drawer.menu.goodPractices')}</TitleBox>,
        to: `/${RouteVal.MANUAL}/${ManualTab.GOOD_PRACTICES}`,
        icon: <ThumbUpAlt fontSize="small" />,
        blank: true,
      },
    ],
  },
]

const TitleBox = ({ children, blank }: { children: React.ReactNode; blank?: boolean }) => (
  <Box display="inline-flex" justifyContent={blank ? 'space-between' : 'initial'} alignItems="center" gap={1} sx={{ width: '100%' }}>
    {children}
    {blank && <OpenInNew fontSize="small" sx={{ color: 'text.disabled' }} />}
  </Box>
)
